@tailwind base;
@tailwind components;
@tailwind utilities;

@import "account-index-login-menu.css";
@import "cafes-instant-search.css";
@import "checkout.css";
@import "flavor-and-roast-collection.css";
@import "quiz.css";
@import "search-dialog-input.css";
@import "yotpo-reviews.css";

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}

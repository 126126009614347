.checkout {
  background-color: #f9f6f0;
  height: 100vh;
}

.checkout > nav {
  padding: 10px 140px;
}

@media screen and (max-width: 640px) {
  .checkout > nav {
    padding: 10px 80px;
  }
}

.checkout > nav > ul {
  display: flex;
  justify-content: space-between;
}

.checkout > nav > ul > li {
  display: flex;
  align-items: center;
}

.search-box-root {
  position: relative;
}

.search-box-input {
  border: 1px solid #dadada;
  border-radius: 3px;
  height: 3.15rem;
  width: 18.5rem;
  padding-left: 4rem;
  padding-right: 1rem;
  font-family: "Halis GR Regular", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #545454;
  background: #ffffff;
  z-index: 0;
}

.search-box-input::placeholder {
  color: #545454;
}

.search-box-submit {
  position: absolute;
  top: 0;
  left: 1rem;
  bottom: 0;
}

@media only screen and (max-width: 640px) {
  .search-box-root {
    display: grid;
    gap: 1rem;
  }
}

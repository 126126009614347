.search-dialog-input-container {
  position: relative;
  width: 100%;
  border: 1px solid var(--Grey, #545454);
  border-radius: 4px;
}

.search-dialog-header {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 42px;
}

.search-dialog-header > button {
  margin-left: auto;
}

.search-dialog-slideOverDialog {
  padding-top: 0px;
  padding-inline: 7px;
}

@media only screen and (min-width: 600px) {
  .search-dialog-header {
    margin-top: 30px;
    margin-bottom: 20px;
    gap: 80px;
  }

  .search-dialog-slideOverDialog {
    padding-inline: 72px;
  }
}

.search-dialog-input-container .search-dialog-input {
  width: 100%;
  padding-left: 40px;
  border: none;
  border-radius: 0.25rem;
  font-family:
    "Halis GR Regular",
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  text-transform: uppercase;
}

.search-dialog-input-container .search-dialog-input::placeholder {
  color: #dadada;
}

.search-dialog-input-container .search-dialog-input-submit {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
}

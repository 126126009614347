:root {
  --sans:
    Halis GR Regular, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  --serif:
    Chronicle Text G1 Roman, ui-serif, Georgia, Cambria, Times New Roman, Times,
    serif;
}

/* Hide the star container in the STAR RATINGS widget */
.yotpo-sr-bottom-line-summary > span:not(.yotpo-sr-bottom-line-right-panel) {
  display: none !important;
}

.yotpo-star-reviews-widget
  .yotpo-sr-bottom-line-text.yotpo-sr-bottom-line-text--right-panel {
  color: #333333 !important;
  font-family: var(--sans) !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
}

/* Homepage reviews carousel DESKTOP */
.yotpo-reviews-carousel-widget-container {
  margin: 0 0 30px 0 !important;
  padding: 0 30px !important;
  width: 100% !important;
}

.yotpo-reviews-carousel-header {
  color: #333333 !important;
  font-family: var(--serif) !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 1.875rem !important;
  text-align: center !important;
}

@media screen and (min-width: 1280px) {
  .yotpo-reviews-carousel-header {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }
}

.reviews-carousel-card-star-rating-shop-badge-container,
.review-title {
  display: none !important;
}

.reviews-carousel-card-container {
  background-color: #fdfbf7 !important;
  border: none !important;
  border-radius: 0% !important;
}

.reviews-carousel-card {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.review-product-image {
  position: absolute;
  top: 35%;
  border-radius: 0 !important;
  height: 135px !important;
  width: 135px !important;
}

.review-product-name,
.reviewer-display-name {
  font-family: var(--sans) !important;
  font-weight: 400 !important;
  letter-spacing: 0.1em;
  text-transform: uppercase !important;
}

.review-product-name {
  color: #333333 !important;
  font-size: 0.875rem !important;
  line-height: 1rem !important;
  overflow: visible !important;
}

@media screen and (min-width: 1280px) {
  .review-product-name {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }
}

.reviews-carousel-card-review-content-container {
  display: flex !important;
  justify-content: flex-end !important;
  flex-grow: 0 !important;
  margin-top: -100px !important;
}

.review-content {
  font-family: var(--serif) !important;
  margin-top: 110px !important;
  height: 165px !important;
  width: 60% !important;
  max-height: min-content !important;
}

/* Read more button */
.review-content span[role="button"] {
  color: #333333 !important;
  font-family: var(--sans) !important;
}

.reviewer-status svg {
  display: none !important;
}

.reviewer-status-text,
.review-date {
  color: #545454 !important;
  font-family: var(--sans) !important;
  font-size: 0.75rem !important;
}

.reviews-carousel-reviewer-info-container {
  font-size: 0.75rem !important;
}

.glide__arrow--left,
.glide__arrow--right {
  border-radius: 0 !important;
}

.glide__arrow--left svg,
.glide__arrow--right svg {
  display: none !important;
}

.glide__arrow--left::before {
  content: "";
  display: inline-block;
  width: 100px;
  height: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KPGcgdHJhbnNmb3JtPSJzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0yMCwgMCkiPgo8cGF0aCBkPSJNMTguMzUzOCAxMC4zNTM2QzE4LjU0OTEgMTAuMTU4MyAxOC41NDkxIDkuODQxNzEgMTguMzUzOCA5LjY0NjQ1TDE1LjE3MTkgNi40NjQ0N0MxNC45NzY2IDYuMjY5MiAxNC42NiA2LjI2OTIgMTQuNDY0NyA2LjQ2NDQ3QzE0LjI2OTUgNi42NTk3MyAxNC4yNjk1IDYuOTc2MzEgMTQuNDY0NyA3LjE3MTU3TDE3LjI5MzIgMTBMMTQuNDY0NyAxMi44Mjg0QzE0LjI2OTUgMTMuMDIzNyAxNC4yNjk1IDEzLjM0MDMgMTQuNDY0NyAxMy41MzU1QzE0LjY2IDEzLjczMDggMTQuOTc2NiAxMy43MzA4IDE1LjE3MTkgMTMuNTM1NUwxOC4zNTM4IDEwLjM1MzZaTTAuODU3NDIyIDEwLjVIMTguMDAwM0wxOC4wMDAzIDkuNUgwLjg1NzQyMkwuODU3NDIyIDEwLjVaIiBmaWxsPSIjMzMzMzMzIi8+CjwvZz4KPC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.glide__arrow--right::after {
  content: "";
  display: inline-block;
  width: 100px;
  height: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTE4LjM1MzggMTAuMzUzNkMxOC41NDkxIDEwLjE1ODMgMTguNTQ5MSA5Ljg0MTcxIDE4LjM1MzggOS42NDY0NUwxNS4xNzE5IDYuNDY0NDdDMTQuOTc2NiA2LjI2OTIgMTQuNjYgNi4yNjkyIDE0LjQ2NDcgNi40NjQ0N0MxNC4yNjk1IDYuNjU5NzMgMTQuMjY5NSA2Ljk3NjMxIDE0LjQ2NDcgNy4xNzE1N0wxNy4yOTMyIDEwTDE0LjQ2NDcgMTIuODI4NEMxNC4yNjk1IDEzLjAyMzcgMTQuMjY5NSAxMy4zNDAzIDE0LjQ2NDcgMTMuNTM1NUMxNC42NiAxMy43MzA4IDE0Ljk3NjYgMTMuNzMwOCAxNS4xNzE5IDEzLjUzNTVMMTguMzUzOCAxMC4zNTM2Wk0wLjg1NzQyMiAxMC41SDE4LjAwMDNMMTguMDAwMyA5LjVIMC44NTc0MjJMMC44NTc0MjIgMTAuNVoiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Homepage reviews carousel MOBILE */
@media screen and (max-width: 1024px) {
  .reviews-carousel-card-container {
    width: 100% !important;
    min-height: 400px !important;
  }

  .review-product {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
  }

  .review-product-image {
    position: relative;
    top: 0;
  }

  .review-content {
    width: 100% !important;
  }
}

.flavorAndRoastCollection-carousel {
  padding: 40px 0;
}

.flavorAndRoastCollection-listClass {
  gap: 10px;
  margin-left: 20px;
  margin-bottom: 40px;
  width: auto;
}

.flavorAndRoastCollection-listItemClass {
  width: 81%;
}

@media only screen and (min-width: 560px) {
  .flavorAndRoastCollection-carousel {
    padding: 32px 0 50px 0px;
  }

  .flavorAndRoastCollection-listClass {
    margin-left: 50px;
    gap: 20px;
  }

  .flavorAndRoastCollection-listItemClass {
    width: auto;
    min-width: 427px;
  }
}

.quiz-modal-wrapper {
  display: contents;
}

.quiz-modal-main-container {
  margin: 0%;
}

@media only screen and (min-width: 640px) {
  .quiz-modal-main-container {
    margin: 1%;
  }
}
